<template>
  <div class="home w-100">
    <div class="card m-t-1" style="background-color: #f1f1f1;">
      <div class=" p-2 m-b-2  py-3">
        <div class="border-bottom pb-3 mx-3">
          <!-- مرحبا بك يا {{ payload.name }} ... -->
          احصائيات عامة
        </div>
      </div>
      <div class="px-3">
        <div class="row p-2">

          <CardStatistic v-for="(value, key, index) in cards" :key="index" :icon="getIcon(key)" :title="getTitle(key)"
            :value="value" />
        </div>
      </div>
      <div class=" p-2 m-b-2  py-3">
        <div class="border-bottom pb-3 mx-3">التقارير الاسبوعية </div>
      </div>
      <div class="px-3 pb-5">
        <div class="row p-2">

          <div class="col-sm-6 col-md-12 col-lg-6 mb-4">
            <div class="card"> 
              <WeeklyReport :chartData="myChartData1" :chartLabels="myChartLabels1" /> 
            </div>
          </div>
          <div class="col-sm-6 col-md-12 col-lg-6 mb-4">
            <div class="card"> 
              <WeeklyReport :chartData="myChartData2" :chartLabels="myChartLabels2" /> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardStatistic from '@/components/Home/CardStatistic.vue';
import WeeklyReport from '@/components/Home/WeeklyChart.vue';

export default {
  components: {
    CardStatistic,
    WeeklyReport,
  },
  data() {
    return {
      cards: {
        buildings: 0,
        real_estates: 0,
        clients: 0,
        sales: 0,
      },
      payload: {
        name: '',
        id: '',
        role: '',
      },
    myChartData1: [5, 7, 10, 12, 14, 16, 18, 20, 22, 24],
    myChartLabels1: ['22/5/2024', '23/5/2024', '24/5/2024', '25/5/2024', '26/5/2024', '27/5/2024', '28/5/2024', '29/5/2024', '30/5/2024', '31/5/2024'],
    myChartData2: [15, 3, 11, 13, 15, 17, 19, 21, 23, 25],
    myChartLabels2: ['22/5/2024', '23/5/2024', '24/5/2024', '25/5/2024', '26/5/2024', '27/5/2024', '28/5/2024', '29/5/2024', '30/5/2024', '31/5/2024'],
    
    };
  },
  mounted() {
    this.getDashboardStatistics();
    this.getToken();
  },
  methods: {
    async getToken() {
      await this.$store.dispatch('getToken');
      this.payload = this.$store.state.payload;
    },
    getDashboardStatistics() {
      this.$store.dispatch('getDashboardStatistics')
        .then(response => {
          this.cards = response.data.cards;
        })
        .catch(error => {
          console.error(error);
        });
    },
    getIcon(key) {
      const icons = {
        buildings: 'fa-building-o',
        real_estates: 'fa-map-marker',
        clients: 'fa-user',
        sales: 'fa-tags',
        paid_payments: 'fa-balance-scale',
        unpaid_payments: 'fa-cubes',
        paid_payments_amount: 'fa-dollar',
        unpaid_payments_amount: 'fa-money',
      };
      return icons[key] || 'fa-question';
    },
    getTitle(key) {
      const titles = {
        buildings: 'البنايات',
        real_estates: 'العقارات',
        clients: 'العملاء',
        sales: 'المبيعات',
        paid_payments: 'عدد الدفعات المسددة',
        unpaid_payments: 'عدد الدفعات غير المسددة',
        paid_payments_amount: 'الدفعات المسددة',
        unpaid_payments_amount: 'الدفعات غير المسددة',
      };
      return titles[key] || '';
    },
  },
};
</script>
<template>
  <div class="p-b-1 mt-3">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="left-options">
        <div class="dropdown">
          <a
            href="http://"
            class="nav-icon_a bg-none"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i
              class="fa fa-user-circle-o nav-icon nav_icon_i"
              style="font-size: 16px; margin: 5px; padding: 8px"
              aria-hidden="true"
            ></i>
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" @click="logout()">تسجيل الخروج</a>
            <a class="dropdown-item" @click="changeLogoModal()"
              >تبديل الشعار
            </a>
          </div>
        </div>

        <div class="dropdown">
          <a
            @click="openChangePrimaryModal()"
            class="bg-none nav-icon_a"
            type="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <!-- <img :src="paletteIcon" width="34"/> -->
            <i
              class="fa fa-edit nav-icon nav-icon_i"
              style="font-size: 16px; margin: 5px; padding: 8px"
              aria-hidden="true"
            ></i>
          </a>
        </div>
      </div>
      <div class="right-title">
        <span class="nav-title">نظام ادارة المجمعات السكنية</span>
      </div>
    </nav>
    <EditLogoComponent ref="editLogoComponent" />
    <SetPrimaryColorComponent ref="setPrimaryColorComponent" />
  </div>
</template>

<script>
import EditLogoComponent from "@/components/Nav/EditLogoComponent.vue";
import SetPrimaryColorComponent from "@/components/Nav/SetPrimaryColorComponent.vue";
export default {
  components: { EditLogoComponent, SetPrimaryColorComponent },
  data() {
    return {
      modalLogo: false,
      loading_spinner: false,
      errors: [],
      paletteIcon: require("@/assets/images/svg/palette-solid.svg"),
    };
  },
  methods: {
    logout() {
      this.$emit("logout");
    },
    changeLogoModal() {
      this.$refs.editLogoComponent.showLogoModal();
    },
    openChangePrimaryModal() {
      this.$refs.setPrimaryColorComponent.showChangePrimaryModal();
    },

    closeModal() {
      $("#modalEdit2").modal("hide");
      this.modalLogo = false;
    },
  },
};
</script>

<template>
    <div>
        <canvas height="300px" ref="chart"></canvas>
    </div>
</template>

<script>
import { Chart, LineController, LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend } from 'chart.js';

Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend);

export default {
    props: {
        chartData: {
            type: Array,
            default: () => [5, 7, 10, 12, 14, 16, 18, 20, 22, 24],

            required: true,
        },
        chartLabels: {
            type: Array,
            default: () => ['22/5/2024', '23/5/2024', '24/5/2024', '25/5/2024', '26/5/2024', '27/5/2024', '28/5/2024', '29/5/2024', '30/5/2024', '31/5/2024'],

            required: true,
        },
    },
    mounted() {
        this.createChart();
    },
    methods: {
        createChart() {
            new Chart(this.$refs.chart, {
                type: 'line',
                data: {
                    labels: this.chartLabels,
                    datasets: [
                        {
                            label: 'تقارير اسبوعية',
                            data: this.chartData,
                            backgroundColor: 'rgba(135, 206, 235, 0.1)', // Light blue
                            borderColor: 'rgb(135, 206, 235)', // Blue
                            pointBackgroundColor: 'rgb(255, 255, 255)', // White
                            pointBorderColor: 'rgb(135, 206, 235)', // Blue
                            pointHoverBackgroundColor: 'rgb(135, 206, 235)', // Blue
                            pointHoverBorderColor: 'rgb(255, 255, 255)', // White
                            tension: 0.1,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            beginAtZero: true,
                        },
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: 'Total Accounts Over Time',
                        },
                        tooltip: {
                            mode: 'index',
                            intersect: false,
                        },
                        hover: {
                            mode: 'nearest',
                            intersect: true,
                        },
                        legend: {
                            display: true,
                        },
                    },
                },
            });
        },
    },
};
</script>
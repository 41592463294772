<template>
  <div class="col-sm-6 col-md-3 col-lg-3 mb-4">
    <div class="card h-100 border-0 shadow">
      <div class="card-body">
        <div
          class="d-flex align-items-center"
          style="justify-content: space-between; margin: 10px"
        >
          <div class="card-statistic-icon flex-shrink-0 mr-3">
            <i :class="'fa ' + icon + ' fa-2x'"></i>
          </div>
          <div>
            <div class="card-statistic-title text-uppercase text-muted mb-2">
              {{ title }}
            </div>
            <div class="card-statistic-number h5 mb-0">
              {{ value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.card-statistic-icon {
  color: var(--primary-color);
}
</style>

<template>
  <!-- <div class="modal fade" id="modalEdit" tabindex="-1" role="dialog" aria-labelledby="modalEditTitle"aria-hidden="true"> -->
  <div class="modal fade" id="EditLogo" tabindex="-1" role="dialog" aria-labelledby="EditLogo1" aria-hidden="true"
    data-backdrop="static">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addModalTitle">
            <i class="fa fa-edit"></i>
            تبديل الشعار
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
         </div>
        <div class="modal-body show-card-body">
          <div class="block">
            <label for="upload-logo" class="upload-logo-label">
              <div class="upload-image-container" :style="{ backgroundImage: `url(${imageSrc})` }">
                <i class="fa fa-upload" aria-hidden="true"></i>
                <span class="extentions">PNG, JPEG</span>
              </div>
            </label>
            <input type="file" class="form-control red-input-border" name="logo" id="upload-logo" accept="image/*"
              hidden @change="previewImage">
            <div v-if="errors.logo">
              <p class="input-error" v-for="(error, index) in errors.logo" :key="index">{{ error }}</p>
            </div>
          </div>
          <button type="button" class="btn btn-primary btn-primary-100 mt-3" @click="uploadLogo">
            <div class="spinner-border" role="status" v-if="loading_spinner">
              <span class="visually-hidden"></span>
            </div>
            <i class="fa fa-floppy-o save-icon" v-else></i> ارسال
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toastr from 'toastr'

export default {
  data() {
    return {
      modalLogo: false,
      loading_spinner: false,
      errors: [],
      file: null,
      imageSrc: require('@/assets/images/gray_background.jpg'), // Use require to resolve the image path
    };
  },
  methods: { 
    previewImage() {
      let file = event.target.files[0];
      let reader = new FileReader();
      this.file = event.target.files[0]
      reader.onload = (e) => {
        this.imageSrc = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    changeLogoModal() {
      // this.$emit("changeLogo");
      $('#EditLogo').modal('show');
      this.modalLogo = true;
    },
    showLogoModal() {
      $('#EditLogo').modal('show');
    },

    uploadLogo() {
      if (this.file!=null&&this.loading_spinner===false) {
        this.loading_spinner=true
        let formData = new FormData();
        formData.append('image', this.file);
        let base_url = this.$axios.defaults.baseURL;
        this.$axios.post(`${base_url}/api/uploadimage`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          this.closeModal();
          toastr.success('تم تغيير الشعار بنجاح');
        }).catch(error => {
          console.log(error);
          this.errors = error.response.data.errors;
          
        }).finally(() => {
          this.loading_spinner = false;
          this.closeModal()
        })
      }
      else if (this.file===null) toastr.warning('الصورة مطلوبة')
    },
  closeModal() {
    $('#EditLogo').modal('hide');
    this.modalLogo = false;
    this.file=null;
      this.imageSrc = require('@/assets/images/gray_background.jpg');

    },
    changeLogoModal() {
      this.$emit('showModal');
    },
  },
};
</script>

<template>
  <div>
    <div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-labelledby="addModalTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-side" role="document">
        <div class="modal-content modal-content-side">
          <div class="modal-header">
            <h5 class="modal-title" id="addModalTitle">
              <i class="fa fa-plus"></i>
              اضافة عميل
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body modal-side">

            <div class="form-group">
              <label for="name">الاسم الكامل</label>
              <input type="text" class="form-control red-input-border" v-model="addForm.name">
              <div v-if="errors.name">
                <p class="input-error" v-for="(error, index) in errors.name" :key="index">{{ error }}</p>
              </div>
            </div>

            <div class="form-group" v-if="role !== 'accountant'">
              <label for="name">
                <span v-if="this.$store.state.payload.complex_section_title == 'ادارة المربعات السكنية'">المربع
                  السكني</span>
                <span v-else>المجمع</span>

              </label>
              <v-select v-model="selected_complex" :options="complexes" label="name" :dir="'rtl'" track-by="id"
                :searchable="true" @search="getGroups" placeholder="ابحث عن مجمع" />
              <div v-if="errors.complex_id">
                <p class="input-error" v-for="(error, index) in errors.complex_id" :key="index">{{ error }}</p>
              </div>
            </div>


            <div class="form-group">
              <label for="nationality">الجنسية</label>
              <select id="nationality" class="form-control red-input-border" v-model="addForm.nationality"
                @change="changeNationality()">
                <option value="عراقي">عراقي</option>
                <option value="اخرى">اخرى</option>
              </select>
              <div v-if="errors.nationality">
                <p class="input-error" v-for="(error, index) in errors.nationality" :key="index">{{ error }}</p>
              </div>
            </div>


            <div class="form-group" v-if="addForm.nationality == 'اخرى'">
              <label for="nationality">الجنسية</label>
              <input type="text" class="form-control red-input-border" v-model="addForm.other_nationality">
              <div v-if="errors.nationality">
                <p class="input-error" v-for="(error, index) in errors.nationality" :key="index">{{ error }}</p>
              </div>
            </div>



            <div class="form-group">
              <label for="job">العمل</label>
              <input type="text" class="form-control red-input-border" v-model="addForm.job">
              <div v-if="errors.job">
                <p class="input-error" v-for="(error, index) in errors.job" :key="index">{{ error }}</p>
              </div>
            </div>


            <div class="form-group">
              <label for="marital_status">الحالة الزوجية</label>
              <select id="marital_status" class="form-control red-input-border" v-model="addForm.marital_status">
                <option value="اعزب">اعزب</option>
                <option value="خاطب">خاطب</option>
                <option value="متزوج">متزوج</option>
              </select>
              <div v-if="errors.marital_status">
                <p class="input-error" v-for="(error, index) in errors.marital_status" :key="index">{{ error }}</p>
              </div>
            </div>


            <div class="form-group">
              <label for="id_number">رقم الهوية</label>
              <input type="number" class="form-control red-input-border" v-model="addForm.id_number">
              <div v-if="errors.id_number">
                <p class="input-error" v-for="(error, index) in errors.id_number" :key="index">{{ error }}</p>
              </div>

            </div>

            <div class="form-group">
              <label for="phone">رقم الهاتف</label>
              <input type="number" class="form-control red-input-border" v-model="addForm.phone">
              <div v-if="errors.phone">
                <p class="input-error" v-for="(error, index) in errors.phone" :key="index">{{ error }}</p>
              </div>

            </div>



            <div class="form-group">
              <label for="home_phone">هاتف المنزل</label>
              <input type="number" class="form-control red-input-border" v-model="addForm.home_phone">
              <div v-if="errors.home_phone">
                <p class="input-error" v-for="(error, index) in errors.home_phone" :key="index">{{ error }}</p>
              </div>

            </div>


            <div class="form-group">
              <label for="visible">اخفاء المعلومات</label>
              <select v-model="addForm.visible" id="visible" class="form-control red-input-border">
                <option value="1">لا</option>
                <option value="0">نعم</option>
              </select>
              <div v-if="errors.visible">
                <p class="input-error" v-for="(error, index) in errors.visible" :key="index">{{ error }}</p>
              </div>
            </div>



            <div class="form-group">
              <label for="work_phone">المستمسكات
              </label>
              <div v-for="(input, index) in inputs" :key="index">
                <div class="card-image-client">
                  <div class="form-row">
                    <button @click="removeInput(index)" class="btn btn-danger delete-image"><i
                        class="fa fa-trash"></i></button>
                    <div class="form-group col-md-12">
                      <input type="file" :id="`paper_${index}`" accept="image/*" hidden
                        @change="handleFileChange(index)">
                      <select class="form-control red-input-border" v-model="addForm.captions[index]">
                        <option  v-if="addForm.nationality == 'عراقي'" value="بطاقة السكن">بطاقة السكن</option>
                        <option  v-if="addForm.nationality == 'اخرى'"  value="جواز السفر">جواز السفر</option>
                        <option  v-if="addForm.nationality == 'عراقي'" value="البطاقة الموحدة">البطاقة الموحدة</option>
                      </select>
                    </div>
                    <label :for="`paper_${index}`" class="upload-logo-label">
                      <div class="upload-image-container" :class="{ 'green': uploaded_files[index] }">
                        <i class="fa fa-upload" aria-hidden="true"></i>
                        <span class="extentions">PNG, JPEG </span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <br>
              <button   @click="addInput" class="btn btn-primary">اضافة صورة</button>
            </div>



            <hr>
            <button type="button" class="btn btn-primary  btn-primary-100" @click="addClient()">
              <div class="spinner-border" role="status" v-if="loading_spinner">
                <span class="visually-hidden"></span>
              </div>
              <i class="fa fa-floppy-o save-icon" v-else></i> اضافة
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      role: '',
      complexes: [],
      loading_spinner: false,
      selected_complex: null,
      uploaded_files: [],
      inputs: [],
      addForm: {
        name: '',
        phone: '',
        home_phone: '',
        work_phone: '',
        nationality: 'عراقي',
        other_nationality: '',
        id_number: '',
        complex_id: null,
        job: '',
        marital_status: 'اعزب',
        files: [],
        captions: [],
        visible: 1,
      },
      errors: [],
    }
  },
  mounted() {
    this.getGroups();
    this.getToken();
  },
  methods: {
    async getToken() {
      await this.$store.dispatch('getToken');
      this.role = this.$store.state.payload.role;
    },
    handleFileChange(index) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      this.addForm.files[index] = file;
      this.uploaded_files.splice(index, 1, true);
    },
    addInput() {
      this.inputs.push(null);
      this.addForm.captions.push('');
      this.addForm.files.push(null);
      this.uploaded_files.push(false);
    },
    removeInput(index) {
      this.inputs.splice(index, 1);
      this.addForm.captions.splice(index, 1);
      this.addForm.files.splice(index, 1);
      this.uploaded_files.splice(index, 1);
    },
    getGroups(query) {
      this.$store.dispatch('getGroups', {
        search: query,
      })
        .then(response => {
          this.complexes = response.data.data;
          if (this.role == 'accountant') {
            this.selected_complex = response.data.data[0];
            console.log(this.selected_complex);
          }
        })
        .catch(error => {
        });
    },
    addClient() {
      if (this.loading_spinner) {
        return;
      }
      let nationality = 'عراقي';
      if (this.addForm.nationality == 'اخرى') {
        nationality = this.addForm.other_nationality;
      }
      this.loading_spinner = true;
      this.addForm.complex_id = this.selected_complex ? this.selected_complex.id : null;
      this.addForm.nationality = nationality;

      this.$store.dispatch('addClient', this.addForm)
        .then(response => {
          this.$emit('addClient', response.data);
          $('#addModal').modal('hide');
          this.addForm = {
            name: '',
            phone: '',
            home_phone: '',
            work_phone: '',
            nationality: 'عراقي',
            id_number: '',
            job: '',
            marital_status: '',
          }
          this.inputs = [];
          this.addForm.captions = [];
          this.addForm.files = [];
          this.uploaded_files = [];
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'تمت الاضافة بنجاح',
            showConfirmButton: false,
            timer: 1500
          });
          this.$emit('getData');
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        });
      this.errors = [];
      this.loading_spinner = false;
    }
  }
}
</script>
<template>
  <div class="home w-100">
    <div class="content">
      <div class="">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="left-options card-title-icons">

                <a data-toggle="modal" data-target="#addModal" class="add-btn-options add-btn"
                  v-if="role == 'super-admin'">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </a>

                <a v-if="selectedItems && selectedItems.length > 0" @click="confirmDeleteGroup()" id="deleteButton"
                  class="card-title-options delete-btn">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </a>

                <a v-if="selectedItems && selectedItems.length == 1" id="editModal" class="card-title-options edit-btn"
                  @click="editGroup()">
                  <i class="fa fa-cog" aria-hidden="true"></i>
                </a>

                <a v-if="selectedItems && selectedItems.length == 1" id="showButton" class="card-title-options show-btn"
                  data-toggle="modal" data-target="#showModal" @click="showGroupModal()">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </a>
              </div>

              <div class="right-title">
                <span class="nav-title">{{ title }}</span>
              </div>
            </div>



            <loading-table-component v-if="loading" />

            <div class="table-responsive" v-else>

              <table class="table table-hover" id="selectableTable">
                <thead>
                  <tr>
                    <th scope="col">ت</th>
                    <th scope="col">اسم {{ name }}</th>
                    <th scope="col">النوع</th>
                    <th scope="col">
                      <span v-if="type == 'buildings'">
                        عدد البنايات
                      </span>
                      <span v-else>
                        عدد المربعات السكنية
                      </span>
                    </th>
                    <th scope="col">الشعار</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="item.id" :class="{ selected: isSelected(item.id) }"
                    @click="handleSelection(item.id)">
                    <td>{{ getIndex(index) }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.type == 'vertical' ? 'عمودي' : 'افقي' }}</td>
                    <td>{{ item.buildings_count }}</td>
                    <td>
                      <img v-if="item.logo" :src="showimage(item.logo)" class="table-image">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="pagination-container" v-if="!loading">
              <pagination-component :currentPage="currentPage" :lastPage="lastPage" @getData="getGroups" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddGroupComponent v-if="role" :location="location" @getData="getGroups" />
    <EditGroupComponent ref="editGroupComponent" :location="location" :id="edit_id" @getData="getGroups" />
    <ShowGroupComponent ref="showGroupComponent" />
    <AddMapComponent @location="getLocationFromMap" :location="location" />
  </div>
</template>


<script>
import AddGroupComponent from '@/components/Groups/AddGroupComponent.vue';
import EditGroupComponent from '@/components/Groups/EditGroupComponent.vue';
import ShowGroupComponent from '@/components/Groups/ShowGroupComponent.vue';
import AddMapComponent from '@/components/Groups/AddMapComponent.vue';

export default {
  components: {
    AddGroupComponent,
    EditGroupComponent,
    ShowGroupComponent,
    AddMapComponent
  },
  data() {
    return {
      // title: this.$store.state.payload.complex_section_title == 'buildings' ? 'ادارة المجمعات' : 'ادارة المربعات السكنية',
      // name: this.$store.state.payload.complex_section_title == 'buildings' ? 'المجمع' : 'المربع السكني',
      title: 'ادارة المجمعات',
      name: 'المجمع',
      location: null,
      loading: false,
      items: [
      ],
      type: '',
      selectedItems: [],
      edit_id: null,
      editModal: false,
      currentPage: 1,
      lastPage: 1,
      role: null,
    };
  },
  watch: {
    location(val) {
      this.location = val
    }
  },
  mounted() {
    this.getGroups(this.currentPage);
    this.getToken();
    this.getType();
  },
  methods: {
    getType() {
      this.type = localStorage.getItem('type');
    },
    async getToken() {
      await this.$store.dispatch('getToken');
      this.role = this.$store.state.payload.role;
    },

    getIndex(index) {
      return index + 1 + (this.currentPage - 1) * 10;
    },
    getGroups(currentPage) {
      this.loading = true;
      this.$store.dispatch('getGroups', {
        page: currentPage,
      })
        .then(response => {
          this.loading = false;
          this.items = response.data.data;
          this.currentPage = response.data.current_page;
          this.lastPage = response.data.last_page;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    editGroup() {
      let id = this.selectedItems[0];
      $('#modalEdit').modal('show');
      this.$refs.editGroupComponent.showGroup(id);
    },
    showGroupModal() {
      console.log("sasdssa")
      let id = this.selectedItems[0];
      this.$refs.showGroupComponent.showGroupInfo(id);
      // $('#showModal').modal('show');
    },
    getLocationFromMap(location) {
      this.location = location;
    },
    isSelected(itemId) {
      return this.selectedItems.includes(itemId);
    },
    handleSelection(itemId) {
      if (this.isSelected(itemId)) {
        this.selectedItems = this.selectedItems.filter((id) => id !== itemId);
      }
      else {
        this.selectedItems.push(itemId);
      }
    },
    showimage(image) {
     // remove string 'public' from image path
      return "https://demo-appartment-api.tatweer-dev.com" + image;
    },
    async confirmDeleteGroup() {
      const result = await this.$swal.fire({
        title: 'هل انت متأكد ؟',
        text: 'لن تتمكن من التراجع عن هذا الاجراء!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'نعم, احذفها!',
        cancelButtonText: 'الغاء',
      });
      if (result.isConfirmed) {
        this.deleteGroup();
      }
    },
    async deleteGroup() {
      try {
        await this.$store.dispatch('deleteGroup', {
          ids: this.selectedItems,
        });
        this.selectedItems = [];
        await this.getGroups(this.currentPage);
        this.$swal({
          position: 'center',
          icon: 'success',
          title: 'تم الحذف بنجاح',
          showConfirmButton: false,
          timer: 1500
        });
      }
      catch (error) {
        await this.$swal.fire('خطآ', 'حدثت مشكلة اثناء الحذف', 'error');
      }
    },
  },
};
</script>

<style>
/* Add your styles for selected rows here if needed */
.selected {
  background-color: #f0f0f0;
}
</style>

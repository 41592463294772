<template>
  <div
    class="modal fade"
    id="showChangePrimaryModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="EditLogo1"
    aria-hidden="true"
    data-backdrop="static"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addModalTitle">
            <i class="fa fa-edit"></i>
            تغير اللون الرئيسي للموقع
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body show-card-body pb-3">
          <div>
            <div class="m-2">
              <p>اختر اللون المناسب للنظام</p>
              <!-- pick color input radio -->
              <input
              class="color-input"
                type="color"
                v-model="primaryColor.hex"
              />
            </div>
            <div class="m-2">
              <p>بعض اقتراحات الالوان المناسبة</p>

              <div class="d-flex flex-wrap">
                <div v-for="(color, index) in colors" :key="index" class="p-2">
                  <input
                    type="radio"
                    :id="color"
                    v-model="primaryColor.hex"
                    :value="color"
                    class="color-radio"
                  />
                  <label
                    :for="color"
                    :style="{ backgroundColor: color }"
                    class="color-label"
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-primary m-3"
          @click="changePrimaryColor(primaryColor.hex)"
        >
          <div class="spinner-border" role="status" v-if="loading_spinner">
            <span class="visually-hidden"></span>
          </div>
          <i class="fa fa-floppy-o save-icon" v-else></i> تغيير اللون
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    const savedColor = localStorage.getItem("primaryColor");
    if (savedColor) {
      this.primaryColor.hex = savedColor;
      document.documentElement.style.setProperty("--primary-color", savedColor);
    } else {
      this.changePrimaryColor("#3676ab");
    }
  },
  data() {
    return {
      primaryColor: {
        hex: "#3676ab", // Replace with your color
      },
      colors: [
        "#FF0000",
        "#008000",
        "#0000FF",
        "#00FFFF",
        "#FF00FF",
        "#d7a88ec7",
        "#000000",
        "#3676ab",
        "#FFA500",
        "#800080",
        "#00FF00",
        "#000080",
      ],

      loading_spinner: false,
    };
  },
  methods: {
    showChangePrimaryModal() {
      $("#showChangePrimaryModal").modal("show");
    },
    changePrimaryColor(color) {
      localStorage.setItem("primaryColor", color);
      document.documentElement.style.setProperty(
        "--primary-color",
        this.primaryColor.hex
      );
      $("#showChangePrimaryModal").modal("hide");
    },
  },
  // ...
};
</script>

<style>
.color-radio {
  display: none;
}

.color-label {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
}

.color-radio:checked + .color-label::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  background: url("@/assets/images/svg/check-solid.svg");
  background-size: cover;
  transform: translate(-50%, -50%);
}
</style>
